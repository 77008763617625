import { $channelId } from '@channel/channel.model'
import type { RemoteOperationParams } from '@farfetched/core'
import { createMutation } from '@farfetched/core'
import { invoke } from '@withease/factories'
import { attach, createEvent, restore, sample } from 'effector'
import type { Except } from 'type-fest'
import { z } from 'zod'
import type { LinkServiceComponents } from '~/api/links'
import { createLinksServiceEffect } from '~/api/links'
import { createFormAtom, createFormField } from '~/shared/lib/factories/form'

export const createTagMutation = createMutation({
  effect: createLinksServiceEffect({
    method: 'post',
    url: '/tags',
  }),
})

export const tagSelectOpenChanged = createEvent<{
  tagTypeId: string
  onSuccess?: (tagId: string) => void
} | null>()
const $tagSelectState = restore(tagSelectOpenChanged, null)

type CreateTagInputs = Except<
  LinkServiceComponents<'TagCreateDTO'>,
  'channel_id'
>

export const nameField = invoke(() =>
  createFormField<string>({ initialValue: '' }),
)
export const colorField = invoke(() =>
  createFormField<string>({
    initialValue: '#3b82f6',
  }),
)
const tagTypeIdField = invoke(() => createFormField<string>())

export const tagForm = invoke(() =>
  createFormAtom({
    schema: z.object({
      name: z.string().min(1),
      color: z.string().min(1),
      tag_type_id: z.string().min(1),
    }) satisfies z.ZodType<CreateTagInputs>,
    fields: {
      name: nameField,
      color: colorField,
      tag_type_id: tagTypeIdField,
    },
  }),
)

sample({
  clock: $tagSelectState,
  filter: Boolean,
  fn: ({ tagTypeId }) => tagTypeId,
  target: tagTypeIdField.setValue,
})

sample({
  clock: $tagSelectState,
  filter: state => !state,
  target: tagTypeIdField.resetValue,
})

sample({
  clock: tagForm.validated,
  source: { channel_id: $channelId },
  fn: ({ channel_id }, inputs) =>
    ({
      body: {
        channel_id: channel_id!,
        ...inputs,
      },
    }) satisfies RemoteOperationParams<typeof createTagMutation>,
  target: createTagMutation.start,
})

sample({
  clock: createTagMutation.finished.success,
  fn: ({ result: { id } }) => id,
  target: attach({
    source: $tagSelectState.map(state => state?.onSuccess ?? null),
    effect: (callback, tagId: string) => {
      if (typeof callback === 'function') {
        callback(tagId)
      }
    },
  }),
})

sample({
  clock: createTagMutation.finished.success,
  target: [nameField.clear, colorField.clear] as const,
})
