import { onAbort } from '@farfetched/core'
import { createEffect } from 'effector'
import type {
  FetchOptions,
  FetchResponse,
  MaybeOptionalInit,
} from 'openapi-fetch'
import createClient from 'openapi-fetch'
import type {
  HttpMethod,
  MediaType,
  PathsWithMethod,
} from 'openapi-typescript-helpers'
import { HOST } from './config'
import { authFetchFx } from './fetcher'
import type * as LinksServiceTypes from './links.generated'

export type LinkServiceComponents<
  T extends keyof LinksServiceTypes.components['schemas'],
> = LinksServiceTypes.components['schemas'][T]

const linksServiceClient = createClient<LinksServiceTypes.paths>({
  baseUrl: `${HOST}/api/proxy/links-service`,
  fetch: authFetchFx,
})

export const updateInviteLinkBaseFx = createEffect({
  handler: async (
    opts: FetchOptions<
      LinksServiceTypes.paths['/invite_links/{invite_link_id}']['patch']
    >,
  ) => {
    const { data, error } = await linksServiceClient.PATCH(
      '/invite_links/{invite_link_id}',
      opts,
    )
    if (error) throw error
    return data
  },
})

export const getInviteLinksBaseFx = createEffect({
  handler: async (
    opts: FetchOptions<LinksServiceTypes.paths['/invite_links/all']['post']>,
  ) => {
    const abortController = new AbortController()

    onAbort(() => {
      abortController.abort()
    })

    opts.signal = abortController.signal

    const { data, error } = await linksServiceClient.POST(
      '/invite_links/all',
      opts,
    )
    if (error) throw error

    return data
  },
})

export const createInviteLinkBaseFx = createEffect({
  handler: async (
    opts: FetchOptions<LinksServiceTypes.paths['/invite_links']['post']>,
  ) => {
    const { data, error } = await linksServiceClient.POST('/invite_links', opts)
    if (error) throw error
    return data
  },
})

export const replaceInviteLinkTagsBaseFx = createEffect({
  handler: async (
    opts: FetchOptions<
      LinksServiceTypes.paths['/invite_links/tags/replace']['post']
    >,
  ) => {
    const { data, error } = await linksServiceClient.POST(
      '/invite_links/tags/replace',
      opts,
    )
    if (error) throw error
    return data
  },
})

export const updateInviteLinksTagBaseFx = createEffect({
  handler: async (
    opts: FetchOptions<LinksServiceTypes.paths['/tags/{tag_id}']['patch']>,
  ) => {
    const { data, error } = await linksServiceClient.PATCH(
      '/tags/{tag_id}',
      opts,
    )
    if (error) throw error
    return data
  },
})

export const getInviteLinksTagsTypesBaseFx = createEffect({
  handler: async (
    opts: FetchOptions<LinksServiceTypes.paths['/tags/types']['get']>,
  ) => {
    const abortController = new AbortController()

    onAbort(() => {
      abortController.abort()
    })

    opts.signal = abortController.signal

    const result = await linksServiceClient.GET('/tags/types', opts)

    if (result.error) throw result.error

    return result.data
  },
})

export const createLinksServiceEffect = <
  Media extends MediaType,
  Method extends HttpMethod,
  Path extends PathsWithMethod<LinksServiceTypes.paths, Method>,
  Init extends MaybeOptionalInit<LinksServiceTypes.paths[Path], Method>,
  Response extends Required<
    FetchResponse<
      NonNullable<LinksServiceTypes.paths[Path][Method]>,
      Init,
      Media
    >
  >,
>({
  method,
  url,
}: {
  method: Method
  url: Path
}) =>
  createEffect(async (init: Init) => {
    const abortController = new AbortController()

    onAbort(() => {
      abortController.abort()
    })

    if (init) {
      init.signal = abortController.signal
    }

    const METHOD = method.toUpperCase() as keyof typeof linksServiceClient

    const fn = linksServiceClient[METHOD] as unknown as (
      url: Path,
      init: Init,
    ) => Promise<Response>

    const { error, data } = await fn(url, init)

    if (error) {
      throw error
    }

    return data
  })

export type LinksServiceHandlerInit<
  Method extends HttpMethod,
  Path extends PathsWithMethod<LinksServiceTypes.paths, Method>,
> = MaybeOptionalInit<LinksServiceTypes.paths[Path], Method>
