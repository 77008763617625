export const HARDCODED_SourceTagsNames = [
  'Telegram',
  'Telegram Ads',
  'Telegram посев',
  'Чат-бот / Web-app',
  'ВК',
  'ВК посев',
  'ВК таргет',
  'Инст',
  'Сайт / Лендинг',
  'Сайт партнера',
  'Фб',
  'Ютуб',
  'Яндекс',
] as const

export const HARDCODED_tagTypesNames = [
  'Source',
  'Medium',
  'Campaign',
  'Content',
] as const
