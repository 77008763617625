import { $channelId } from '@channel/channel.model'
import type { RemoteOperationParams } from '@farfetched/core'
import { concurrency, createQuery } from '@farfetched/core'
import {
  attach,
  combine,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector'
import { combineEvents } from 'patronum'
import type { LinkServiceComponents } from '~/api/links'
import { createLinksServiceEffect } from '~/api/links'
import { HARDCODED_SourceTagsNames } from '~/HARDCODED'
import type { Tag } from '../invite_links_dialog/types'
import { createTagMutation } from './create.model'

export const tagsQuery = createQuery({
  effect: attach({
    effect: createLinksServiceEffect({ method: 'get', url: '/tags' }),
  }),
})

concurrency(tagsQuery, {
  strategy: 'TAKE_LATEST',
})

const tagsTypesQuery = createQuery({
  effect: createLinksServiceEffect({
    method: 'get',
    url: '/tags/types',
  }),
  initialData: [],
})

concurrency(tagsQuery, {
  strategy: 'TAKE_LATEST',
})

const $tags = createStore<LinkServiceComponents<'TagResponseDTO'>[]>([])

const $tagsCurrentPage = createStore(1)
const nextPage = createEvent()
sample({
  clock: nextPage,
  source: $tagsCurrentPage,
  fn: currentPage => currentPage + 1,
  target: $tagsCurrentPage,
})

sample({
  clock: $tagsCurrentPage,
  source: { channel_id: $channelId },
  fn: ({ channel_id }, page) =>
    ({
      params: {
        query: {
          channel_id: channel_id!,
          page,
          limit: 10_000,
        },
      },
    }) satisfies RemoteOperationParams<typeof tagsQuery>,
  target: tagsQuery.refresh,
})

export const $hasMoreTags = combine([tagsQuery.$data], ([tagsData]) => {
  const value = (tagsData?.total ?? 0) > (tagsData?.limit ?? 0)
  return value
})

export const loadMoreTags = createEvent()

sample({
  clock: loadMoreTags,
  filter: $hasMoreTags,
  target: nextPage,
})

sample({
  clock: tagsQuery.finished.success,
  fn: ({ result: { items } }) => {
    return items
  },
  target: $tags,
})

export const $allTagsOptionsGroups = combine(
  [$tags, tagsTypesQuery.$data],
  ([tags, tagsTypes]) => {
    const tagsTypesMap = new Map(
      tagsTypes.map(({ id, name }) => [
        id,
        {
          id,
          name,
          options: [] as {
            value: LinkServiceComponents<'TagResponseDTO'>['id']
            label: LinkServiceComponents<'TagResponseDTO'>['name']
            color: LinkServiceComponents<'TagResponseDTO'>['color']
            hasLinks: boolean
          }[],
        },
      ]),
    )

    for (const tag of tags) {
      tagsTypesMap.get(tag.type.id)?.options.push({
        value: tag.id,
        label: tag.name,
        color: tag.color,
        hasLinks: (tag.links ?? []).length > 0,
      })
    }

    return Array.from(tagsTypesMap.values())
  },
)

sample({
  clock: createTagMutation.finished.success,
  source: $tags,
  fn: (currentTags, { result: newTag }) => {
    return [...currentTags, newTag]
  },
  target: $tags,
})

sample({
  clock: $channelId,
  filter: Boolean,
  fn: () => ({}),
  target: tagsTypesQuery.refresh,
})

export const forceRefreshTags = createEvent()

sample({
  clock: $channelId,
  target: [$tags.reinit, tagsQuery.reset] as const,
})

sample({
  clock: [$channelId, forceRefreshTags],
  source: $channelId,
  filter: Boolean,
  fn: channel_id =>
    ({
      params: {
        query: {
          channel_id,
          limit: 10_000,
        },
      },
    }) satisfies RemoteOperationParams<typeof tagsQuery>,
  target: tagsQuery.refresh,
})

export const $showTagsSkeleton = restore(
  sample({
    clock: combineEvents([
      tagsQuery.finished.finally,
      tagsTypesQuery.finished.finally,
    ]),
    fn: () => false,
  }),
  true,
)

// HARDCODED DEFAULT SOURCE TAGS
export const $sourceTagsTypeId = combine(
  [tagsTypesQuery.$data],
  ([tagsTypes]) => {
    return tagsTypes.find(({ name }) => name === 'Source')?.id ?? null
  },
)

export const $missedHardcodedTagsNames = combine(
  [$tags, tagsTypesQuery.$data],
  ([tags, tagTypes]) => {
    const sourceTagTypeId = tagTypes.find(({ name }) => name === 'Source')?.id

    if (!sourceTagTypeId) return []

    const currentSourceTagsNames = [] as Tag['name'][]

    for (const tag of tags) {
      if (tag.type.id === sourceTagTypeId) {
        currentSourceTagsNames.push(tag.name)
      }
    }

    const missedNames = [] as Tag['name'][]

    for (const name of HARDCODED_SourceTagsNames) {
      if (!currentSourceTagsNames.includes(name)) {
        missedNames.push(name)
      }
    }

    return missedNames
  },
)

export const createHardcodedTagClicked = createEvent<{
  tagName: Tag['name']
  tagColor: Tag['color']
}>()

sample({
  clock: createHardcodedTagClicked,
  source: {
    channelId: $channelId,
    sourceTagsTypeId: $sourceTagsTypeId,
  },
  filter: ({ sourceTagsTypeId }) => !!sourceTagsTypeId,
  fn: ({ channelId, sourceTagsTypeId }, { tagName, tagColor }) =>
    ({
      body: {
        channel_id: channelId!,
        color: tagColor,
        name: tagName,
        tag_type_id: sourceTagsTypeId!,
      },
    }) satisfies RemoteOperationParams<typeof createTagMutation>,
  target: createTagMutation.start,
})
