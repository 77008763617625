import { onAbort } from '@farfetched/core'
import { createEffect } from 'effector'
import type { FetchOptions } from './client'
import { API } from './client'

export const getChannelAccessesBaseFx = createEffect({
  handler: async (opts: FetchOptions<'get', '/accesses'>) => {
    const abortController = new AbortController()

    onAbort(() => {
      abortController.abort()
    })

    opts.signal = abortController.signal

    const { data, error } = await API.accessService.auth.GET('/accesses', opts)
    if (error) throw error
    if (!data[0]) throw new Error('Доступ не найден')
    return data[0]
  },
})
