import { createFactory } from '@withease/factories'
import type { Json } from 'effector'
import { createEvent, createStore, sample } from 'effector'

function createStateAtomImpl<State, SerializedState extends Json = Json>({
  defaultState,
  skipVoid = false,
  ...config
}: {
  defaultState: Parameters<typeof createStore<State, SerializedState>>[0]
} & Parameters<typeof createStore<State, SerializedState>>[1]) {
  const $value = createStore(defaultState, {
    skipVoid,
    ...config,
  })
  const set = createEvent<State>()
  const reset = $value.reinit
  sample({ clock: set, target: $value })

  return {
    '@@unitShape': () => ({
      value: $value,
      setValue: set,
      reset,
    }),
    $value,
    set,
    reset,
  }
}

export const createStateAtom = createFactory(createStateAtomImpl)
