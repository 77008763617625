import type { RemoteOperationParams } from '@farfetched/core'
import { concurrency, createQuery } from '@farfetched/core'
import type { CheckedState } from '@radix-ui/react-checkbox'
import { createFactory, invoke } from '@withease/factories'
import type { Event, Store } from 'effector'
import { combine, createEvent, createStore, sample } from 'effector'
import equal from 'fast-deep-equal/es6'
import { produce } from 'immer'
import { condition, debounce, spread } from 'patronum'
import { getInviteLinksBaseFx } from '~/api/links'
import { EMPTY_INVITE_LINK_HASH } from '~/constants'
import { createDisclosureAtom } from '~/shared/lib/factories/create-disclosure'
import type {
  InviteLink,
  InviteLinksOrder,
  Tag,
  TagsFiltering,
  TagType,
} from '../../invite_links_dialog/types'

type Config = {
  channelId: Store<number | null>
  isEnabled: Store<boolean>
  load?: Event<unknown>
}

type Option = {
  label: string
  value: string
  createdAt: string
  isChecked: boolean
  isFiltered: boolean
}

function createInviteLinksWidgetModelImpl({
  channelId,
  isEnabled,
  load = createEvent(),
}: Config) {
  const comboBoxDisclosureAtom = invoke(() => createDisclosureAtom())
  // Filters and Sorting
  const $nameFilter = createStore<string>('')
  const nameFilterChanged = createEvent<string>()
  sample({ clock: nameFilterChanged, target: $nameFilter })

  const $sortOrder = createStore<InviteLinksOrder>('-created_at')
  const sortOrderChanged = createEvent<InviteLinksOrder>()
  sample({ clock: sortOrderChanged, target: $sortOrder })

  const $checkedTagsIdsKv = createStore<Record<TagType['id'], Tag['id'][]>>({})
  const $selectedEmptyTagTypesIds = createStore<TagType['id'][]>([])

  const emptyTagTypeIdSelected = createEvent<TagType['id']>()

  const tagsChecked = createEvent<{
    tagTypeId: TagType['id']
    tagsIds: Tag['id'][]
  }>()

  const resetTagsByTagTypeId = createEvent<{
    tagTypeId: TagType['id']
  }>()

  sample({
    clock: tagsChecked,
    source: { kv: $checkedTagsIdsKv },
    fn: ({ kv }, { tagTypeId, tagsIds }) => {
      return produce(kv, draft => {
        if (tagsIds.length === 0) {
          delete draft[tagTypeId]
        } else {
          draft[tagTypeId] = tagsIds
        }
      })
    },
    target: $checkedTagsIdsKv,
  })

  sample({
    clock: emptyTagTypeIdSelected,
    source: { arr: $selectedEmptyTagTypesIds },
    fn: ({ arr }, tagTypeId) => {
      return arr.includes(tagTypeId)
        ? arr.filter(currentTagTypeId => currentTagTypeId !== tagTypeId)
        : [...arr, tagTypeId]
    },
    target: $selectedEmptyTagTypesIds,
  })

  sample({
    clock: resetTagsByTagTypeId,
    source: { kv: $checkedTagsIdsKv, arr: $selectedEmptyTagTypesIds },
    fn: ({ kv, arr }, { tagTypeId }) => {
      return {
        tagsIds: produce(kv, draft => {
          delete draft[tagTypeId]
        }),
        emptyTagsTypesIds: arr.filter(
          currentTagTypeId => currentTagTypeId !== tagTypeId,
        ),
      }
    },
    target: spread({
      tagsIds: $checkedTagsIdsKv,
      emptyTagsTypesIds: $selectedEmptyTagTypesIds,
    }),
  })

  // Links
  const filterQuery = createQuery({
    effect: getInviteLinksBaseFx,
    mapData: data => data.result.items.map(({ id }) => id),
    initialData: [],
  })

  concurrency(filterQuery, {
    strategy: 'TAKE_LATEST',
  })

  const allInviteLinksQuery = createQuery({
    effect: getInviteLinksBaseFx,
    mapData: data => data.result.items,
    initialData: [],
  })

  const forcedLoad = createEvent()

  const loadAllInviteLinks = sample({
    clock: [comboBoxDisclosureAtom.opened, forcedLoad, load],
    source: allInviteLinksQuery.$data,
    filter: items => items.length === 0,
  })

  concurrency(allInviteLinksQuery, {
    strategy: 'TAKE_LATEST',
  })

  const $options = createStore<Option[]>([])

  const $totalInviteLinks = combine([$options], ([options]) => options.length)

  const $checkedOptions = combine([$options], ([options]) => {
    return options.filter(option => option.isChecked)
  })

  const $checkedOptionsValues = $checkedOptions.map(options =>
    options.map(({ value }) => value),
  )

  const $optionsCheckedState = combine(
    [$options],
    ([options]): CheckedState => {
      if (options.length === 0) return false
      if (options.every(({ isChecked }) => isChecked)) return true
      return options.some(({ isChecked }) => isChecked)
        ? 'indeterminate'
        : false
    },
  )

  const $inviteLinksHashList = createStore<InviteLink['id'][]>([], {
    updateFilter: (next, prev) => !equal(next, prev),
  })

  const $hasActiveFilters = combine(
    [$nameFilter, $checkedTagsIdsKv, $selectedEmptyTagTypesIds],
    ([name, tagsKv, tagsTypesIds]) => {
      const isDefaultName = name === ''
      const isDefaultTags = Object.values(tagsKv).flat().length === 0
      const isDefaultTagsTypes = tagsTypesIds.length === 0

      return !(isDefaultName && isDefaultTags && isDefaultTagsTypes)
    },
  )

  const $hasActiveFiltersAndSorting = combine(
    [$nameFilter, $sortOrder, $checkedTagsIdsKv, $selectedEmptyTagTypesIds],
    ([name, order, tagsKv, tagsTypesIds]) => {
      const isDefaultName = name === ''
      const isDefaultOrder = order === '-created_at'
      const isDefaultTags = Object.values(tagsKv).flat().length === 0
      const isDefaultTagsTypes = tagsTypesIds.length === 0

      return !(
        isDefaultName &&
        isDefaultOrder &&
        isDefaultTags &&
        isDefaultTagsTypes
      )
    },
  )

  const $noLinksFound = combine(
    [$hasActiveFilters, filterQuery.$data, filterQuery.$status],
    ([hasFilter, links, queryStatus]) => {
      if (queryStatus === 'initial' || queryStatus === 'pending') {
        return false
      }
      return hasFilter && links.length === 0
    },
  )

  const $loadingInviteLinks = combine(
    [filterQuery.$pending, allInviteLinksQuery.$pending],
    queries => queries.some(Boolean),
  )

  const optionToggled = createEvent<Pick<Option, 'value'>>()
  const allOptionsCheckedStateToggled = createEvent()
  const allOptionsChecked = createEvent()
  const allOptionsUnchecked = createEvent()

  sample({
    clock: allInviteLinksQuery.finished.success,
    fn: ({ result }) => [
      {
        value: EMPTY_INVITE_LINK_HASH,
        label: 'Без ссылки',
        createdAt: new Date().toISOString(),
        isChecked: false,
        isFiltered: false,
      } as const,
      ...result.map(({ id, name, invite_link, created_at }) => ({
        value: id,
        label: name ?? invite_link,
        createdAt: created_at,
        isChecked: false,
        isFiltered: false,
      })),
    ],
    target: $options,
  })

  sample({
    clock: filterQuery.$data,
    source: {
      currentOptions: $options,
      hasActiveFilters: $hasActiveFilters,
    },
    fn: ({ currentOptions, hasActiveFilters }, filteredLinksIds) => {
      const ids = hasActiveFilters ? filteredLinksIds : []

      return currentOptions
        .map(currentOption => {
          const prevIsFiltered = currentOption.isFiltered
          const prevIsChecked = currentOption.isChecked

          const isInFiltered = ids.includes(currentOption.value)

          return {
            ...currentOption,
            isFiltered: isInFiltered,
            isChecked: isInFiltered || (!prevIsFiltered && prevIsChecked),
          }
        })
        .sort((a, b) => {
          if (a.value === EMPTY_INVITE_LINK_HASH) return -1
          if (b.value === EMPTY_INVITE_LINK_HASH) return 1

          if (a.isChecked !== b.isChecked) {
            return a.isChecked ? -1 : 1
          } else {
            return 0
          }
        })
    },
    target: $options,
  })

  sample({
    clock: optionToggled,
    source: $options,
    fn: (options, { value }) => {
      return produce(options, draft => {
        const option = draft.find(option => option.value === value)
        if (option) {
          option.isChecked = !option.isChecked
        }

        draft.sort((a, b) => {
          if (a.value === EMPTY_INVITE_LINK_HASH) return -1
          if (b.value === EMPTY_INVITE_LINK_HASH) return 1

          if (a.isChecked !== b.isChecked) {
            return a.isChecked ? -1 : 1
          } else {
            return 0
          }
        })
      })
    },
    target: $options,
  })

  condition({
    source: allOptionsCheckedStateToggled,
    if: combine($optionsCheckedState, state => {
      return state === 'indeterminate' || state === false
    }),
    then: allOptionsChecked,
    else: allOptionsUnchecked,
  })

  sample({
    clock: allOptionsChecked,
    source: $options,
    fn: options =>
      options.map(option => ({
        ...option,
        isChecked: true,
      })),
    target: $options,
  })

  sample({
    clock: allOptionsUnchecked,
    source: $options,
    fn: options =>
      options.map(option => ({
        ...option,
        isChecked: option.isFiltered || false,
      })),
    target: $options,
  })

  sample({
    clock: [
      debounce($checkedTagsIdsKv, 1000),
      debounce($selectedEmptyTagTypesIds, 1000),
      debounce($nameFilter, 2000),
      debounce($sortOrder, 1000),
    ],
    source: {
      channelId: channelId,
      tagsKv: $checkedTagsIdsKv,
      name: $nameFilter,
      order: $sortOrder,
      emptyTagsTypesIds: $selectedEmptyTagTypesIds,
    },
    filter: isEnabled,
    fn: ({ channelId, tagsKv, name, order, emptyTagsTypesIds }) => {
      const tagsBody: TagsFiltering[] = []

      for (const [tagTypeId, tagsIds] of Object.entries(tagsKv)) {
        tagsBody.push({
          tag_type_id: tagTypeId,
          tags: tagsIds,
          without_type: false,
        })
      }

      for (const emptiedTagTypeId of emptyTagsTypesIds) {
        const currentItem = tagsBody.find(
          ({ tag_type_id }) => tag_type_id === emptiedTagTypeId,
        )

        if (currentItem) {
          currentItem.without_type = true
        } else {
          tagsBody.push({
            tag_type_id: emptiedTagTypeId,
            without_type: true,
            tags: [],
          })
        }
      }

      return {
        params: {
          query: {
            channel_id: channelId!,
            limit: -1,
            name: name.trim().toLocaleLowerCase(),
            ordered_fields: [order],
          },
        },
        body: {
          tags: tagsBody,
        },
      } satisfies RemoteOperationParams<typeof filterQuery>
    },
    target: filterQuery.refresh,
  })

  sample({
    clock: [loadAllInviteLinks, debounce($sortOrder, 1000)],
    source: {
      inviteLinks: allInviteLinksQuery.$data,
      channelId,
      order: $sortOrder,
    },
    fn: ({ channelId, order }) =>
      ({
        params: {
          query: {
            channel_id: channelId!,
            limit: -1,
            ordered_fields: [order],
          },
        },
        body: {
          tags: [],
        },
      }) satisfies RemoteOperationParams<typeof allInviteLinksQuery>,
    target: allInviteLinksQuery.refresh,
  })

  sample({
    clock: debounce($checkedOptionsValues, 1000),
    target: $inviteLinksHashList,
  })

  const $clientSearch = createStore('')
  const clientSearchChanged = createEvent<string>()
  const clientSearchClear = $clientSearch.reinit
  sample({ clock: clientSearchChanged, target: $clientSearch })

  // TODO: Возможно стоит добавить сюда debounce
  const $clientOptions = combine(
    [$options, $clientSearch],
    ([options, search]) =>
      options.filter(option =>
        option.label
          .toLocaleLowerCase()
          .includes(search.trim().toLocaleLowerCase()),
      ),
  )

  const resetTagsFilter = createEvent()
  const resetAll = createEvent()
  const clearModel = createEvent()

  sample({
    clock: resetTagsFilter,
    target: [
      $checkedTagsIdsKv.reinit,
      $selectedEmptyTagTypesIds.reinit,
    ] as const,
  })

  sample({
    clock: resetAll,
    target: [
      $nameFilter.reinit,
      $sortOrder.reinit,
      $checkedTagsIdsKv.reinit,
      $selectedEmptyTagTypesIds.reinit,
      $clientSearch.reinit,
      allOptionsUnchecked,
    ] as const,
  })

  sample({
    clock: clearModel,
    target: [
      filterQuery.reset,
      allInviteLinksQuery.reset,
      $options.reinit,
      $inviteLinksHashList.reinit,
      $nameFilter.reinit,
      $sortOrder.reinit,
      $checkedTagsIdsKv.reinit,
      $selectedEmptyTagTypesIds.reinit,
      $clientSearch.reinit,
    ],
  })

  sample({
    clock: channelId,
    target: clearModel,
  })

  return {
    // Filters and sorting
    $nameFilter,
    nameFilterChanged,
    $sortOrder,
    sortOrderChanged,

    $checkedTagsIdsKv,
    $selectedEmptyTagTypesIds,
    tagsChecked,
    emptyTagTypeIdSelected,
    resetTagsByTagTypeId,
    resetTagsFilter,

    // Queries
    allInviteLinksQuery,
    filterQuery,

    // Common
    $options,
    $optionsCheckedState,
    $checkedOptions,
    $checkedOptionsValues,
    optionToggled,
    allOptionsCheckedStateToggled,

    $totalInviteLinks,
    $inviteLinksHashList,
    $hasActiveFiltersAndSorting,
    $noLinksFound,
    $loadingInviteLinks,
    resetAll,
    clearModel,

    $clientSearch,
    clientSearchChanged,
    clientSearchClear,
    $clientOptions,

    $disclosureIsOpen: comboBoxDisclosureAtom.$isOpen,
    setDisclosureIsOpen: comboBoxDisclosureAtom.setIsOpen,
    closeDisclosure: comboBoxDisclosureAtom.close,
    openDisclosure: comboBoxDisclosureAtom.open,

    forcedLoad,
    $hasActiveFilters,
  }
}

export const createInviteLinksWidgetModel = createFactory(
  createInviteLinksWidgetModelImpl,
)

export type InviteLinksWidgetModel = ReturnType<
  typeof createInviteLinksWidgetModel
>
