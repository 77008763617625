import { createFactory } from '@withease/factories'
import { createEvent, createStore, sample } from 'effector'

export function createDisclosureAtomImpl(config: { initial?: boolean } = {}) {
  const { initial = false } = config
  const $isOpen = createStore(initial)
  const open = createEvent()
  const close = createEvent()
  const setIsOpen = createEvent<boolean>()
  const toggleOpen = createEvent()

  sample({
    clock: toggleOpen,
    source: $isOpen,
    fn: isOpen => !isOpen,
    target: $isOpen,
  })

  sample({
    clock: open,
    fn: () => true,
    target: $isOpen,
  })

  sample({
    clock: close,
    fn: () => false,
    target: $isOpen,
  })

  sample({
    clock: setIsOpen,
    target: $isOpen,
  })

  const closed = sample({
    clock: $isOpen,
    filter: is => !is,
  })

  const opened = sample({
    clock: $isOpen,
    filter: Boolean,
  })

  return {
    $isOpen: $isOpen,
    open: open,
    close: close,
    setIsOpen: setIsOpen,
    opened: opened,
    closed: closed,
    toggleOpen: toggleOpen,
  }
}

export const createDisclosureAtom = createFactory(createDisclosureAtomImpl)
