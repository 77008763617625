import type { DeepPartial } from 'react-hook-form'
import type { DefaultColors } from 'tailwindcss/types/generated/colors'

export const colors = {
  warning: 'hsl(var(--color-warning-hsl))',
  primary: 'hsl(var(--color-primary-hsl))',
  secondary: 'hsl(var(--color-secondary-hsl))',
  danger: 'hsl(var(--color-danger-hsl))',
  success: 'hsl(var(--color-success-hsl))',
  'gentle-morning-sky': 'hsl(var(--color-gentle-morning-sky-hsl))',
  'blazing-sunset': 'hsl(var(--color-blazing-sunset-hsl))',
  'passionate-ruby': 'hsl(var(--color-passionate-ruby-hsl))',
  'cotton-candy-bliss': 'hsl(var(--color-cotton-candy-bliss-hsl))',
  dark: 'hsl(var(--color-dark-hsl))',
  light: 'hsl(var(--color-light-hsl))',
  // App
  border: 'hsl(var(--color-border-hsl))',
  background: 'hsl(var(--color-background-hsl))',
  foreground: 'hsl(var(--color-foreground-hsl))',
  muted: 'hsl(var(--color-muted-hsl))',
} as const

export const twColors = {
  white: '#fff',
  neutral: {
    '600': '#525252',
  },
  stone: {
    '700': '#44403c',
    '800': '#292524',
    '900': '#1c1917',
    '950': '#0c0a09',
  },
  gray: {
    200: '#e5e7eb',
  },
  orange: {
    '600': '#ea580c',
  },
} satisfies DeepPartial<DefaultColors>
