/* eslint-disable @typescript-eslint/no-explicit-any */
import { createFactory } from '@withease/factories'
import type { EventCallable } from 'effector'
import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector'
import { reset as patronumReset, spread } from 'patronum'
import { mapValues, omit } from 'remeda'
import type { ZodType } from 'zod'
import { ZodError } from 'zod'

type FormFieldAtom<V = unknown> = ReturnType<typeof createFormFieldAtomImpl<V>>

const createFormFieldAtomImpl = <V, R = HTMLElement>(config?: {
  initialValue?: V
  name?: string
  disabled?: boolean
}) => {
  const { name, initialValue, disabled = false } = config || {}

  const $name = createStore(name, { skipVoid: false })

  const $value = createStore(initialValue, { skipVoid: false })
  const setValue = createEvent<V>()

  sample({
    clock: setValue,
    target: $value,
  })

  const resetValue = $value.reinit

  const $ref = createStore<R | null>(null, { skipVoid: false })
  const setRef = createEvent<R | null>()

  sample({
    clock: setRef,
    target: $ref,
  })

  const resetRef = $ref.reinit

  const $isTouched = createStore(false, { skipVoid: false })
  const setIsTouched = createEvent<boolean>()

  sample({
    clock: setIsTouched,
    target: $isTouched,
  })

  const touched = createEvent()

  sample({
    clock: touched,
    fn: () => true,
    target: $isTouched,
  })

  const resetIsTouched = $isTouched.reinit

  const $isDirty = createStore(false, { skipVoid: false })

  const setIsDirty = createEvent<boolean>()

  sample({
    clock: setIsDirty,
    target: $isDirty,
  })

  sample({
    clock: $value,
    fn: value => value !== initialValue,
    target: setIsDirty,
  })

  const resetIsDirty = $isDirty.reinit

  const $errorMessages = createStore<string[]>([], { skipVoid: false })

  const setErrorMessages = createEvent<string[]>()

  sample({
    clock: setErrorMessages,
    target: $errorMessages,
  })

  sample({
    clock: $value,
    target: $errorMessages.reinit,
  })

  const resetErrorMessages = $errorMessages.reinit

  const $isValid = combine(
    [$errorMessages],
    ([errMessage]) => errMessage.length === 0,
  )

  const $isDisabled = createStore(disabled, { skipVoid: false })

  const setDisabled = createEvent<boolean>()

  sample({
    clock: setDisabled,
    target: $isDisabled,
  })

  const resetDisabled = $isDisabled.reinit

  const disable = createEvent()

  sample({
    clock: disable,
    fn: () => true,
    target: setDisabled,
  })

  const enable = createEvent()

  sample({
    clock: enable,
    fn: () => false,
    target: setDisabled,
  })

  const clear = patronumReset({
    target: [$value, $isDirty, $isTouched, $errorMessages, $isDisabled],
  }) as EventCallable<void>

  sample({
    clock: clear,
    source: $ref,
    filter: ref =>
      ref instanceof HTMLInputElement || ref instanceof HTMLTextAreaElement,
    target: createEffect<R, void>(el => {
      ;(el as HTMLInputElement | HTMLTextAreaElement).value = ''
    }),
  })

  const reset = patronumReset({
    target: [
      $value,
      $isDirty,
      $isTouched,
      $errorMessages,
      $name,
      $ref,
      $isDisabled,
    ],
  }) as EventCallable<void>

  return {
    $name,
    $value,
    setValue,
    resetValue,
    $ref,
    setRef,
    resetRef,
    $isTouched,
    setIsTouched,
    resetIsTouched,
    $isDirty,
    setIsDirty,
    resetIsDirty,
    $errorMessages,
    setErrorMessages,
    resetErrorMessages,
    clear,
    reset,
    $isValid,
    $isDisabled,
    setDisabled,
    resetDisabled,
    disable,
    enable,
    touched,
  }
}

type FormRecordFieldAtom<V extends Record<string, unknown>> = ReturnType<
  typeof createRecordFormFieldImpl<V>
>

const createRecordFormFieldImpl = <
  V extends Record<string, unknown>,
  R = HTMLElement,
>(config?: {
  initialValue?: V
  name?: string
  disabled?: boolean
}) => {
  const { name, initialValue = {} as V, disabled = false } = config || {}

  const $name = createStore(name, { skipVoid: false })

  const $value = createStore(initialValue, { skipVoid: false })
  const setValue = createEvent<V>()

  sample({
    clock: setValue,
    target: $value,
  })

  const set = createEvent<{
    key: Extract<keyof V, string>
    value: V[keyof V]
  }>()

  sample({
    clock: set,
    source: $value,
    fn: (currentKv, part) => {
      return {
        ...currentKv,
        [part.key]: part.value,
      } as V
    },
    target: $value,
  })

  const remove = createEvent<{ key: Extract<keyof V, string> }>()

  sample({
    clock: remove,
    source: $value,
    fn: (currentKv, { key }) => {
      return omit(currentKv, [key]) as V
    },
    target: $value,
  })

  const resetValue = $value.reinit

  const $ref = createStore<R | null>(null, { skipVoid: false })
  const setRef = createEvent<R>()

  sample({
    clock: setRef,
    target: $ref,
  })

  const resetRef = $ref.reinit

  const $isTouched = createStore(false, { skipVoid: false })
  const setIsTouched = createEvent<boolean>()

  sample({
    clock: setIsTouched,
    target: $isTouched,
  })

  const touched = createEvent()

  sample({
    clock: touched,
    fn: () => true,
    target: $isTouched,
  })

  const resetIsTouched = $isTouched.reinit

  const $isDirty = createStore(false, { skipVoid: false })

  const setIsDirty = createEvent<boolean>()

  sample({
    clock: setIsDirty,
    target: $isDirty,
  })

  sample({
    clock: $value,
    fn: value => value !== initialValue,
    target: setIsDirty,
  })

  const resetIsDirty = $isDirty.reinit

  const $errorMessages = createStore<string[]>([], { skipVoid: false })

  const setErrorMessages = createEvent<string[]>()

  sample({
    clock: setErrorMessages,
    target: $errorMessages,
  })

  const resetErrorMessages = $errorMessages.reinit

  const $isValid = combine(
    [$errorMessages],
    ([errMessage]) => errMessage.length === 0,
  )

  const $isDisabled = createStore(disabled, { skipVoid: false })

  const setDisabled = createEvent<boolean>()

  sample({
    clock: setDisabled,
    target: $isDisabled,
  })

  const resetDisabled = $isDisabled.reinit

  const disable = createEvent()

  sample({
    clock: disable,
    fn: () => true,
    target: setDisabled,
  })

  const enable = createEvent()

  sample({
    clock: enable,
    fn: () => false,
    target: setDisabled,
  })

  const clear = patronumReset({
    target: [$value, $isDirty, $isTouched, $errorMessages, $isDisabled],
  }) as EventCallable<void>

  const reset = patronumReset({
    target: [
      $value,
      $isDirty,
      $isTouched,
      $errorMessages,
      $name,
      $ref,
      $isDisabled,
    ],
  }) as EventCallable<void>

  return {
    $name,
    $value,
    setValue,
    resetValue,
    $ref,
    setRef,
    resetRef,
    $isTouched,
    setIsTouched,
    resetIsTouched,
    $isDirty,
    setIsDirty,
    resetIsDirty,
    $errorMessages,
    setErrorMessages,
    resetErrorMessages,
    clear,
    reset,
    $isValid,
    $isDisabled,
    setDisabled,
    resetDisabled,
    disable,
    enable,
    set,
    remove,
    touched,
  }
}

export const createRecordFormField = createFactory(createRecordFormFieldImpl)

export const createFormField = createFactory(createFormFieldAtomImpl)

const createFormImpl = <Values extends Record<string, any>>(config: {
  fields: {
    [Prop in keyof Values]:
      | FormFieldAtom<Values[Prop]>
      | FormRecordFieldAtom<Values[Prop]>
  }
  schema: ZodType<Values>
}) => {
  const { fields, schema } = config

  const $values = combine(
    mapValues(
      fields,
      field => (field as FormFieldAtom<any> | FormRecordFieldAtom<any>).$value,
    ),
  )

  const $fieldsErrorMessages = combine(
    mapValues(
      fields,
      field =>
        (field as FormFieldAtom<any> | FormRecordFieldAtom<any>).$errorMessages,
    ) as unknown as Partial<Record<Extract<keyof Values, string>, string[]>>,
  )

  const submit = createEvent()

  const validateFx = attach({
    source: { values: $values },
    effect: ({ values }) => {
      return schema.parseAsync(values)
    },
  })

  const $isValidating = validateFx.pending

  sample({
    clock: submit,
    target: validateFx,
  })

  sample({
    clock: validateFx.failData,
    filter: (cause: Error): cause is ZodError => cause instanceof ZodError,
    fn: cause => {
      const errors = cause.flatten().fieldErrors
      return errors
    },
    target: spread(
      Object.entries(fields).reduce(
        (result, [key, value]) => {
          result[key as Extract<keyof Values, string>] = value.$errorMessages
          return result
        },
        {} as Record<
          Extract<keyof Values, string>,
          (FormFieldAtom<any> | FormRecordFieldAtom<any>)['$errorMessages']
        >,
      ),
    ),
  })

  const $error = createStore<unknown>(null, { skipVoid: false })

  const setError = createEvent<unknown>()

  sample({
    clock: setError,
    target: $error,
  })

  const resetError = $error.reinit

  sample({
    clock: validateFx.failData,
    filter: cause => !(cause instanceof ZodError),
    target: $error,
  })

  sample({
    clock: validateFx.done,
    target: $error.reinit,
  })

  const $isValid = combine(
    Object.values(fields).map(field => field.$isValid),
    fields => {
      return fields.every(field => field)
    },
  )

  const $isTouched = combine(
    Object.values(fields).map(field => field.$isTouched),
    fields => {
      return fields.every(field => field)
    },
  )

  const $isDirty = combine(
    Object.values(fields).map(field => field.$isDirty),
    fields => {
      return fields.every(field => field)
    },
  )

  const validated = validateFx.doneData

  const clear = createEvent()

  sample({
    clock: clear,
    target: Object.values(fields).map(field => field.clear),
  })

  const reset = createEvent()

  sample({
    clock: reset,
    target: Object.values(fields).map(field => field.reset),
  })

  sample({
    clock: [clear, reset],
    target: resetError,
  })

  return {
    $values,
    submit,
    $isValidating,
    $isValid,
    $isTouched,
    $isDirty,
    validated,
    clear,
    reset,
    $error,
    validateFx,
    setError,
    resetError,
    $fieldsErrorMessages,
    fields,
    schema,
  }
}

export const createFormAtom = createFactory(createFormImpl)
